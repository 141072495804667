<template>
  <div class="checkin-rule-time">
    <a-button @click="showAddRuleTime" style="margin-bottom: 10px" v-if="editable">添加</a-button>
    <div class="rule-time-table" v-show="list.length > 0">
      <a-table
        size="middle"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        bordered
        :row-key="(record) => record[rowKey]"
      >
        <template v-slot:action="text, record, index">
          <div class="row-btn">
            <a class="txt-btn" @click.stop="editTime(record, index)">编辑</a>
            <a class="txt-btn danger" @click.stop="deleteTime(record, index)">删除</a>
          </div>
        </template>
      </a-table>
    </div>
    <a-modal
      title="打卡时间"
      :maskClosable="false"
      :closable="false"
      :width="800"
      @ok="handleConfirm"
      @cancel="handleCancel"
      v-model="visible"
    >
      <a-form-model
        ref="ruleTimeForm"
        label-align="left"
        :model="form"
        :rules="rules"
        :label-col="{ span: 4 }"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="工作日" prop="day" :wrapper-col="wrapperCol">
          <a-checkbox-group v-model="form.day" :options="daysOptions"></a-checkbox-group>
        </a-form-model-item>
        <a-form-model-item label="打卡时间" prop="time" :wrapper-col="wrapperCol">
          <time-range v-model="form.time" :min="['', '12:01']" :max="['11:59', '']" :prefix="['上班', '下班']" gap-Str="—"></time-range>
        </a-form-model-item>
        <a-form-model-item label="打卡时段设置" prop="range" :wrapper-col="wrapperCol">
          <checkin-rule-period v-model="form.range" :checkin-time="form.time"></checkin-rule-period>
        </a-form-model-item>
        <a-form-model-item label="弹性上下班" :wrapper-col="wrapperCol">
          <a-checkbox v-model="lateConfig.enableElasticity" @change="handleElasticityChange">开启</a-checkbox>
          <a-radio-group
            v-model="lateConfig.elasticityType"
            :disabled="!lateConfig.enableElasticity"
            style="display: block;"
          >
            <a-radio :style="radioStyle" value="cdzt">
              <span>允许迟到早退</span>
              <div class="flex-box align-center rule-late-config" v-show="lateConfig.elasticityType == 'cdzt'">
                <span class="rule-late-text">上班允许迟到</span>
                <a-select
                  class="rule-late-select"
                  v-model="lateConfig.cd"
                  :options="lateMinutesOptions"
                  style="width: 100px"
                ></a-select>
                <span class="rule-late-text">，下班允许早退</span>
                <a-select
                  class="rule-late-select"
                  v-model="lateConfig.zt"
                  :options="lateMinutesOptions"
                  style="width: 100px"
                ></a-select>
              </div>
            </a-radio>
            <a-radio :style="radioStyle" value="zdzz">
              <span>允许早到早走、晚到晚走</span>
              <div class="flex-box align-center rule-late-config" v-show="lateConfig.elasticityType == 'zdzz'">
                <span class="rule-late-text">最多早到早走</span>
                <a-select
                  class="rule-late-select"
                  v-model="lateConfig.zdzz"
                  :options="lateMinutesOptions"
                  style="width: 100px"
                ></a-select>
                <span class="rule-late-text">，最多晚到晚走</span>
                <a-select
                  class="rule-late-select"
                  v-model="lateConfig.wdwz"
                  :options="lateMinutesOptions"
                  style="width: 100px"
                ></a-select>
              </div>
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import CheckinRulePeriod from "./CheckinRulePeriod";
import { lateMinutesOptions, weekDayOptions, defaultTimeForm, defaultLateConfig, getDayText } from "../rule";
import { clone, omit, randomString } from "../../../../common/js/tool";

export default {
  name: "CheckinRuleTime",
  model: {
    event: "change",
  },
  components: {
    CheckinRulePeriod,
  },
  props: {
    value: Array,
    rule: Object,
    mode: {
      type: String,
      default: "add",
    },
    editable: {
      type: Boolean,
      default: true
    },
    rowKey: {
      type: String,
      default: "key"
    }
  },
  data() {
    return {
      form: {},
      lateConfig: clone(defaultLateConfig),
      visible: false,
      lateMinutesOptions,
      wrapperCol: { span: 20 },
      rules: {
        day: [{ required: true, message: "请选择工作日", trigger: "change" }],
        time: [{ required: true, message: "请设置打卡时间", trigger: "change" }],
        range: [{ required: true, message: "请设置打卡时段", trigger: "change" }],
      },
      radioStyle: {
        display: "block",
        lineHeight: "30px",
      },
      index: null,
    };
  },
  computed: {
    list: {
      get() {
        return this.value ? [...this.value] : [];
      },
      set(val) {
        this.$emit("change", val);
      },
    },
    leftDays() {
      const form = this.form;
      const rowKey = this.rowKey;
      const list = this.list;
      const weekdays = weekDayOptions.map((item) => item.value);
      list.forEach((item) => {
        // 当前form的days也可以选择
        if (item[rowKey] == form[rowKey]) {
          return;
        }
        const days = item.days.split(",").map(Number);
        days.forEach((d) => {
          const i = weekdays.indexOf(d);
          if (i >= 0) {
            weekdays.splice(i, 1);
          }
        });
      });
      return weekdays;
    },
    daysOptions() {
      const leftDays = this.leftDays;
      return weekDayOptions.map((item) => {
        const o = omit(item, ["title"]);
        if (leftDays.indexOf(o.value) === -1) {
          o.disabled = true;
        }
        return o;
      });
    },
    columns() {
      let res = [
        { title: "打卡日", dataIndex: "days", width: "50%", customRender: getDayText },
        {
          title: "上下班时间",
          dataIndex: "time",
          customRender: (text, record) => `上班 ${record.start_time} — 下班 ${record.end_time}`,
        },
      ]
      if (this.editable) {
        res.push({ title: "操作", key: "action", width: 100, scopedSlots: { customRender: "action" } });
      }
      return res;
    },
  },
  methods: {
    editTime(record, index) {
      this.index = index;
      const rowKey = this.rowKey;
      this.form = {
        [rowKey]: record[rowKey],
        day: record.days.split(",").map(Number),
        time: [record.start_time, record.end_time],
        range: record.time_range ? JSON.parse(record.time_range) : undefined
      };
      const lateConfig = {};
      if (record.allow_late == 1) {
        lateConfig.enableElasticity = true;
        if (record.allow_late_config) {
          try {
            const conf = JSON.parse(record.allow_late_config);
            if (conf.cdzt) {
              lateConfig.elasticityType = "cdzt";
              lateConfig.cd = conf.cdzt.cd;
              lateConfig.zt = conf.cdzt.zt;
            } else if (conf.zdzz) {
              lateConfig.elasticityType = "zdzz";
              lateConfig.zdzz = conf.cdzt.zdzz;
              lateConfig.wdwz = conf.cdzt.wdwz;
            }
          } catch (e) {
            console.error(e);
          }
        }
      }
      this.lateConfig = lateConfig;
      this.visible = true;
    },
    deleteTime(record, index) {
      const list = [...this.list];
      if (this.mode == "add") {
        list.splice(index, 1);
        this.list = list;
      } else {
        this.$confirm({
          title: "提示",
          content: "确定删除此打卡时间吗？",
          onOk: () => {
            this.$axios({
              url: `/admin/checkin-rule-time/${record.id}`,
              method: "DELETE",
            }).then(() => {
              list.splice(index, 1);
              this.list = list;
            });
          },
        });
      }
    },
    handleCancel() {
      this.form = {};
    },
    handleConfirm() {
      this.$refs.ruleTimeForm.validate((valid) => {
        if (valid) {
          const rowKey = this.rowKey;
          const lateConfig = this.lateConfig;
          const form = this.form;
          const data = {
            [rowKey]: form[rowKey],
            days: form.day.join(","),
            start_time: form.time[0],
            end_time: form.time[1],
            time_range: JSON.stringify(form.range),
          };
          if (this.mode == "add") {
            data.key = form.key;
          }
          data.allow_late = lateConfig.enableElasticity ? 1 : 0;
          if (lateConfig.enableElasticity) {
            const allow_late_config = {};
            if (lateConfig.elasticityType == "cdzt") {
              allow_late_config.cdzt = {
                cd: lateConfig.cd,
                zt: lateConfig.zt,
              };
            } else {
              allow_late_config.zdzz = {
                zdzz: lateConfig.zdzz,
                wdwz: lateConfig.wdwz,
              };
            }
            data.allow_late_config = JSON.stringify(allow_late_config);
          }
          this.updateRuleTime(data).then(() => {
            this.visible = false;
            this.form = {};
          });
        }
      });
    },
    updateRuleTime(data) {
      const index = this.index;
      const list = [...this.list];
      // 新增规则 抛出change事件
      if (this.mode == "add") {
        if (index === null) {
          list.push(data);
        } else {
          list.splice(index, 1, data);
        }
        this.list = list;
        return Promise.resolve();
      } else {
        // 编辑规则 直接修改数据库
        let url = "/admin/checkin-rule-time",
          method = "POST";
        if (index === null) {
          data.rule_id = this.rule.id;
        } else {
          const item = this.list[index];
          url += `/${item.id}`;
          method = "PATCH";
        }
        return this.$axios({
          url,
          method,
          data,
        }).then((res) => {
          if (index === null) {
            list.push(res);
          } else {
            list.splice(index, 1, res);
          }
          this.list = list;
        });
      }
    },
    showAddRuleTime() {
      this.index = null;
      this.visible = true;
      this.form = this.list.length == 0 ? clone(defaultTimeForm) : omit(defaultTimeForm, ["day"]);
      this.form.key = randomString(16);
      this.lateConfig = clone(defaultLateConfig);
    },
    handleElasticityChange(e) {
      const checked = e.target.checked;
      if (checked) {
        this.$set(this.lateConfig, "elasticityType", "cdzt");
      } else {
        this.$set(this.lateConfig, "elasticityType", undefined);
      }
    },
  },
};
</script>

<style lang="less">
.rule-time-table {
  margin-bottom: 10px;
}
.rule-late-config {
  margin-top: 10px;
  font-size: 12px;
  line-height: 1;
}
.rule-late-text {
  margin-right: 10px;
  color: @text-color-secondary;
}
.rule-late-select {
  margin-right: 10px;
}
</style>
