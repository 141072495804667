<template>
  <div class="special-date">
    <a-button @click="showAddDate" style="margin-bottom: 10px" v-if="!show">添加无需打卡日期</a-button>
    <div class="special-date-table" v-show="list.length > 0">
      <a-table size="middle" :columns="columns" :data-source="list" :pagination="false" bordered :row-key="record => record.key">
        <template v-slot:action="text, record, index" v-if="!show">
          <div class="row-btn">
            <a class="txt-btn" @click.stop="editDate(record, index)">编辑</a>
            <a class="txt-btn danger" @click.stop="deleteDate(index)">删除</a>
          </div>
        </template>
      </a-table>
    </div>
    <a-modal
      title="打卡时间"
      :maskClosable="false"
      :closable="false"
      :width="800"
      @ok="handleConfirm"
      v-model="visible"
    >
      <a-form-model
        ref="dateForm"
        label-align="left"
        :model="form"
        :rules="rules"
        :label-col="{ span: 4 }"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="事由" prop="note" :wrapper-col="{ span: 12 }">
          <a-input v-model="form.note" placeholder="请输入事由"></a-input>
        </a-form-model-item>
        <a-form-model-item label="添加方式" prop="way" :wrapper-col="wrapperCol">
          <a-radio-group v-model="form.way" :options="watchOptions"></a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="选择日期" prop="date" :wrapper-col="wrapperCol">
          <a-date-picker v-model="form.date" value-format="YYYY-MM-DD" format="YYYY-MM-DD dddd" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { randomString } from '../../../../common/js/tool';
export default {
  name: "CheckinSpecialDate",
  model: {
    event: "change",
  },
  props: {
    value: String,
    show: Boolean, // 仅展示
  },
  data() {
    return {
      form: {},
      watchOptions: [
        { label: "单个日期", value: 1 },
        // { label: "时间段", value: 2 },
      ],
      rules: {
        note: [{ required: true, message: "请输入事由", trigger: "blur" }],
        way: [{ required: true, message: "请选择添加方式", trigger: "change" }],
        date: [
          { required: true, message: "请选择日期", trigger: "change" },
          { validator: this.checkDate, trigger: "change" },
        ],
      },
      wrapperCol: { span: 20 },
      visible: false,
      index: null,
    };
  },
  computed: {
    list: {
      get() {
        let res = [];
        const value = this.value;
        if (value) {
          try {
            res = JSON.parse(value);
          } catch (e) {
            console.error(e);
          }
        }
        return res;
      },
      set(val) {
        this.$emit("change", JSON.stringify(val));
      },
    },
    columns() {
      let columns = [
        { title: "无需打卡日", dataIndex: "date", width: "50%" },
        { title: "事由", dataIndex: "note" },
      ];
      if (!this.show) {
        columns.push({ title: "操作", key: "action", width: 100, scopedSlots: { customRender: "action" } });
      }
      return columns;
    },
  },
  methods: {
    handleConfirm() {
      this.$refs.dateForm.validate((valid) => {
        if (valid) {
          this.updateList();
          this.visible = false;
        }
      });
    },
    updateList() {
      const form = this.form;
      const list = [...this.list];
      const item = { ...form };
      if (this.index === null) {
        list.push(item);
      } else {
        list.splice(this.index, 1, item);
      }
      this.list = list;
    },
    showAddDate() {
      this.index = null;
      this.form = {
        key: randomString(16),
        way: 1,
      };
      this.visible = true;
    },
    editDate(record, index) {
      this.index = index;
      this.form = { ...record };
      this.visible = true;
    },
    deleteDate(index) {
      const list = [...this.list];
      list.splice(index, 1);
      this.list = list;
    },
    checkDate(rule, value, callback) {
      const list = [...this.list];
      if (this.index !== null) {
        list.splice(this.index, 1);
      }
      if (list.findIndex((item) => item.date == value) >= 0) {
        callback(new Error(`${value}已被指定为不用打卡的日期，请更换日期`));
      } else {
        callback();
      }
    },
  },
};
</script>

<style lang="less">
.special-date-table {
  margin-bottom: 10px;
}
</style>
