<template>
  <div class="checkin-rule-period">
    <div class="rule-period-title">{{ showTime }}可打卡 <span class="txt-btn" @click="editPeriod">修改</span></div>
    <div class="rule-period-tip">可设置每次上下班允许打卡的时间范围。</div>
    <a-modal
      title="修改打卡区间"
      :maskClosable="false"
      :closable="false"
      :width="600"
      @ok="handleConfirm"
      v-model="visible">
      <div class="rule-period-table">
        <div class="rule-period-tr rule-period-head">
          <div class="rule-period-td" v-for="c in columns" :key="c.dataIndex">{{ c.title }}</div>
        </div>
        <div class="rule-period-tr">
          <div class="rule-period-td">
            <a-checkbox v-model="work.required" disabled>上班需要打卡</a-checkbox>
          </div>
          <div class="rule-period-td">
            <div class="flex-box align-center">
              <span style="margin-right: 0.5em">可打卡时间</span>
              <period-time v-model="work.start" :end-time="getOffsetTime(startTime, -1)" @change="checkTime" />
              <span style="margin: 0 0.5em">至</span>
              <period-time v-model="work.end" :start-time="getOffsetTime(startTime, 1)" end-time="12:00" />
            </div>
          </div>
        </div>
        <div class="rule-period-tr">
          <div class="rule-period-td">
            <a-checkbox v-model="offWork.required" disabled>下班需要打卡</a-checkbox>
          </div>
          <div class="rule-period-td">
            <div class="flex-box align-center">
              <span style="margin-right: 0.5em">可打卡时间</span>
              <period-time v-model="offWork.start" start-time="12:01" :end-time="getOffsetTime(endTime, -1)" />
              <span style="margin: 0 0.5em">至</span>
              <period-time v-model="offWork.end" :start-time="getOffsetTime(endTime, 1)" :end-time="getOffsetTime(work.start, 24 * 60 - 1)" @change="checkTime" />
            </div>
          </div>
        </div>
      </div>
      <div class="rule-period-error" v-if="error">{{ error }}</div>
    </a-modal>
  </div>
</template>

<script>
    import periodTime from "./period-time";
    import { getDoubleText, clone } from "../../../../common/js/tool";
    import { getTimeMinutes, getHourTimeText } from "../rule";

    export default {
        name: "CheckinRulePeriod",
        model: {
            event: "change"
        },
        props: {
            checkinTime: Array,
            value: Object
        },
        components: {
            periodTime
        },
        data() {
            return {
                visible: false,
                columns: [
                    { title: '是否打卡', dataIndex: 'period', scopedSlots: { customRender: 'period'} },
                    { title: '允许打卡范围', dataIndex: 'range', scopedSlots: { customRender: 'range'} },
                ],
                work: {
                    start: '04:00',
                    end: '12:00',
                    required: true
                },
                offWork: {
                    start: '12:01',
                    end: '27:59',
                    required: true
                },
                config: {
                    work: {
                        start: '04:00',
                        end: '12:00',
                        required: true
                    },
                    offWork: {
                        start: '12:01',
                        end: '27:59',
                        required: true
                    },
                },
                error: ""
            }
        },
        computed: {
            startTime() {
                return this.checkinTime ? this.checkinTime[0] : undefined;
            },
            endTime() {
                return this.checkinTime ? this.checkinTime[1] : undefined;
            },
            showTime() {
                const config = this.config;
                return `${config.work.start}-${getHourTimeText(config.offWork.end)}`;
            }
        },
        watch: {
            value() {
                this.setConfig();
            }
        },
        created() {
            this.setConfig();
        },
        methods: {
            setConfig() {
                let res = {
                    work: {
                        start: '04:00',
                        end: '12:00',
                        is_require: 1
                    },
                    offWork: {
                        start: '12:01',
                        end: '27:59',
                        is_require: 1
                    }
                }
                const value = this.value;
                if(value) {
                    res = clone(value);
                } else {
                    this.dispatchChange();
                }
                this.config = res;
            },
            handleConfirm() {
                this.checkTime();
                if(!this.error) {
                    this.dispatchChange();
                    this.visible = false;
                }
            },
            dispatchChange() {
                const work = this.work, offWork = this.offWork;
                this.$emit("change", {
                    work: {
                        start: work.start,
                        end: work.end,
                        is_require: work.required ? 1 : 0
                    },
                    offWork: {
                        start: offWork.start,
                        end: offWork.end,
                        is_require: offWork.required ? 1 : 0
                    }
                });
            },
            checkTime() {
                let error = ""
                const work = this.work, offWork = this.offWork;
                if(getTimeMinutes(work.start) <= getTimeMinutes(offWork.end) - 24 * 60) {
                    error = "下班最晚打卡时间应早于上班最早打卡时间";
                }
                this.error = error;
            },
            editPeriod() {
                this.visible = true;
                this.setEditTime();
            },
            setEditTime() {
                const config = this.config;
                ["work", "offWork"].forEach(key => {
                    const item = config[key];
                    this[key] = {
                        start: item.start,
                        end: item.end,
                        required: item.is_require == 1
                    }
                })
            },
            getOffsetTime(t, offset) {
                if(t) {
                    let minutes = getTimeMinutes(t);
                    minutes += offset;
                    return this.getFormatTime(minutes);
                }
            },
            getFormatTime(minutes) {
                const h = Math.floor(minutes / 60);
                const m = minutes % 60;
                return `${getDoubleText(h)}:${getDoubleText(m)}`;
            }
        }
    }
</script>

<style lang="less">
.rule-period-table {
  border-radius: 4px;
  border: var(--border);
}
.rule-period-tr {
  display: flex;
  align-items: center;
  &:not(:first-of-type) {
    border-top: var(--border);
  }
  &.rule-period-head {
    background-color: @primary-1;
  }
}
.rule-period-td {
  padding: 6px 12px;
  &:first-of-type {
    width: 30%;
  }
  &:last-of-type {
    flex: 1;
    min-width: 0;
    border-left: var(--border);
  }
}
.rule-period-title {
  font-size: 14px;
}
.rule-period-tip {
  line-height: 1.5;
  color: @text-color-secondary;
  font-size: 12px;
}
.rule-period-error {
  margin-top: 16px;
  height: 16px;
  line-height: 16px;
  color: @error-color;
}
</style>
