<template>
  <div class="checkin-rule-location">
    <a-button @click="showAddLocation" class="rule-location-add" v-if="!show">添加</a-button>
    <div class="rule-location-table" v-show="list.length > 0">
      <a-table size="middle" :columns="columns" :data-source="list" :pagination="false" bordered :row-key="record => record.key">
        <template v-slot:action="text, record, index" v-if="!show">
          <div class="row-btn">
            <a class="txt-btn danger" @click.stop="deleteLocation(index)">删除</a>
          </div>
        </template>
      </a-table>
    </div>
    <a-modal
      title="打卡时间"
      :maskClosable="false"
      :closable="false"
      :width="800"
      @ok="handleConfirm"
      v-model="visible"
    >
      <a-form-model
        ref="locationForm"
        label-align="left"
        :model="form"
        :rules="rules"
        :label-col="{ span: 4 }"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="打卡地点" prop="location" :wrapper-col="wrapperCol">
          <div class="rule-location-map">
            <iframe
              id="mapPage"
              width="100%"
              height="100%"
              frameborder="0"
              src="https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=VW3BZ-MENWN-RW6FH-SBBMC-67QHQ-OAFD6&referer=myapp"
            ></iframe>
          </div>
        </a-form-model-item>
        <a-form-model-item label="打卡范围" prop="range" :wrapper-col="{ span: 12 }">
          <a-select v-model="form.range" :options="rangeOptions" placeholder="请选择打卡范围"></a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { randomString } from '../../../../common/js/tool';
export default {
  name: "CheckinRuleLocation",
  model: {
    event: "change",
  },
  props: {
    value: Array,
    show: Boolean,
  },
  data() {
    return {
      rangeOptions: [
        { key: 50, title: "50米" },
        ...new Array(9).fill(0).map((item, i) => Object({ key: (i + 1) * 100, title: (i + 1) * 100 + "米" })),
        { key: 1500, title: "1500米" },
        { key: 2000, title: "2000米" },
        { key: 2500, title: "2500米" },
        { key: 3000, title: "3000米" },
      ],
      form: {},
      rules: {
        location: [{ required: true, message: "请选择打卡地点", trigger: "change" }],
        range: [{ required: true, message: "请选择打卡范围", trigger: "change" }],
      },
      wrapperCol: { span: 20 },
      visible: false,
      index: null,
    };
  },
  computed: {
    list: {
      get() {
        return this.value ? [...this.value] : [];
      },
      set(val) {
        this.$emit("change", val);
      },
    },
    columns() {
      let columns = [
        { title: "地点名称", dataIndex: "location", width:  "50%", customRender: (text) => (text ? text.address : "") },
        { title: "有效范围", dataIndex: "range", customRender: (text) => text + "米" },
      ];
      if (!this.show) {
        columns.push({ title: "操作", key: "action", width: 100, scopedSlots: { customRender: "action" } });
      }
      return columns;
    },
  },
  created() {
    window.addEventListener("message", this.handlePosition, false);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.handlePosition, false);
  },
  methods: {
    handlePosition(e) {
      // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
      let loc = e.data;
      if (loc && loc.module == "locationPicker") {
        //防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
        // console.log(loc);
        const location = {
          lat: loc.latlng.lat,
          lng: loc.latlng.lng,
          latLng: loc.latlng.lat + "," + loc.latlng.lng,
          name: loc.poiname,
          address: loc.poiaddress,
        };
        this.$set(this.form, "location", location);
      }
    },
    handleConfirm() {
      this.$refs.locationForm.validate((valid) => {
        if (valid) {
          this.updateList();
          this.visible = false;
        }
      });
    },
    updateList() {
      const form = this.form;
      const list = [...this.list];
      const item = { ...form };
      if (this.index === null) {
        list.push(item);
      } else {
        list.splice(this.index, 1, item);
      }
      this.list = list;
    },
    showAddLocation() {
      this.form = {
        key: randomString(16),
        range: 300,
      };
      this.visible = true;
    },
    deleteLocation(index) {
      const list = [...this.list];
      list.splice(index, 1);
      this.list = list;
    },
  },
};
</script>

<style lang="less">
.rule-location-add,
.rule-location-table {
  margin-bottom: 10px;
}
.rule-location-map {
  margin-bottom: 10px;
  height: 600px;
  border: var(--border);
}
</style>
