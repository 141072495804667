<template>
  <a-form-model
    ref="ruleForm"
    label-align="left"
    :model="form"
    :rules="rules"
    :label-col="{ span: 3 }"
    :wrapper-col="{ span: 21 }"
  >
    <div class="other-title">规则设置</div>
    <a-form-model-item label="规则名称" prop="name" :wrapper-col="{ span: 8 }">
      <a-input v-model="form.name" placeholder="请输入规则名称"></a-input>
    </a-form-model-item>
    <a-form-model-item label="打卡人员" prop="user_ids" :wrapper-col="{ span: 16 }">
      <select-book multiple type="book" v-model="form.user_ids" @change="checkUser"></select-book>
    </a-form-model-item>
    <a-form-model-item label="规则类型" prop="type">
      <div class="rule-type-list">
        <div
          class="rule-type-item"
          :class="{ 'rule-type-select': form.type == r.key }"
          @click="selectRuleType(r)"
          v-for="r in ruleTypeList"
          :key="r.key"
        >
          <div class="rule-type-name">{{ r.title }}</div>
          <div class="rule-type-desc">{{ r.desc }}</div>
          <div class="type-select-icon">
            <a-icon type="check" class="type-select-check" />
          </div>
        </div>
      </div>
    </a-form-model-item>
    <a-form-model-item label="打卡时间" prop="rule_time" v-if="form.type == 1">
      <checkin-rule-time :row-key="rule ? 'id' : 'key'" v-model="form.rule_time"></checkin-rule-time>
    </a-form-model-item>
    <template v-else-if="form.type == 2">
      <a-form-model-item label="打卡时间" prop="freeRuleTime">
        <a-checkbox-group v-model="form.freeRuleTime" :options="dayOptions"></a-checkbox-group>
      </a-form-model-item>
      <a-form-model-item label="打卡时间间隔" prop="freeTimeSpace">
        上班打卡后
        <a-select
          v-model="form.freeTimeSpace"
          :options="spaceOptions"
          placeholder="请选择时间"
          allowClear
          style="width: 120px; margin: 0 12px;"
        ></a-select>
        可打下班卡
      </a-form-model-item>
    </template>
    <a-form-model-item label="特殊日期">
      <checkin-special-date v-model="form.special_config"></checkin-special-date>
    </a-form-model-item>
    <a-form-model-item label="节假日">
      <a-checkbox v-model="form.holiday_config">
        中国法定节假日不用打卡<span class="rule-form-tip">法定节假日、调休期间不用打卡，补班日期需打卡</span>
      </a-checkbox>
    </a-form-model-item>
    <div class="other-title">打卡方式</div>
    <a-form-model-item label="选择方式" prop="method">
      <a-radio-group v-model="form.method">
        <a-radio :style="radioStyle" :value="3">手机<span class="rule-form-tip">通过位置进行打卡</span></a-radio>
        <!-- <a-radio :style="radioStyle" :value="1">
          手机+考勤机<span class="rule-form-tip">通过位置+考勤机进行打卡</span>
        </a-radio>
        <a-radio :style="radioStyle" :value="2">
          考勤机<span class="rule-form-tip">在考勤机上打卡，考勤记录可查</span>
        </a-radio> -->
      </a-radio-group>
    </a-form-model-item>
    <a-form-model-item label="打卡位置" prop="location_config">
      <checkin-rule-location v-model="form.location_config"></checkin-rule-location>
    </a-form-model-item>
    <div class="other-title">补卡设置</div>
    <a-form-model-item label="补卡申请" prop="allow_complement">
      <a-checkbox v-model="form.allow_complement">
        开启<span class="rule-form-tip">开启后员工异常打卡时可提交申请，审批通过修正异常</span>
      </a-checkbox>
    </a-form-model-item>
    <a-form-model-item label=" " :colon="false">
      <div class="allow-complement-content">
        <a-form-model-item label="允许补卡时间限制">
          <a-select
            v-model="form.timeLimit"
            :options="timeOptions"
            placeholder="请选择时间"
            allowClear
            style="width: 240px;"
            :disabled="!form.allow_complement"
          ></a-select>
        </a-form-model-item>
        <a-form-model-item label="每月允许补卡次数">
          <a-select
            v-model="form.count"
            :options="countOptions"
            placeholder="请选择次数"
            allowClear
            style="width: 240px;"
            :disabled="!form.allow_complement"
          ></a-select>
        </a-form-model-item>
        <a-form-model-item label="每月补卡截止日期">
          <a-select
            v-model="form.dateLimit"
            :options="dateOptions"
            placeholder="请选择日期"
            allowClear
            style="width: 240px;"
            :disabled="!form.allow_complement"
          ></a-select>
        </a-form-model-item>
      </div>
    </a-form-model-item>
    <div class="other-title">更多设置</div>
    <a-form-model-item label="手机提醒">
      默认不发送未打卡提醒
    </a-form-model-item>
    <a-form-model-item label="范围外打卡">
      不在打卡范围内不能打卡，提示您不在打卡范围内
    </a-form-model-item>
    <a-form-model-item label="外出打卡记录同步">
      <a-checkbox :checked="true" disabled>
        开启
        <span class="rule-form-tip">
          开启后，员工在上下班期间外出打卡记录将同步至上下班中，结果显示为「外出打卡」
        </span>
      </a-checkbox>
    </a-form-model-item>
    <a-form-model-item label="生效时间" prop="effect_flag">
      <div class="effect-time-list flex-box align-center">
        <div
          class="effect-time-item"
          :class="{ 'effect-time-select': item.value == form.effect_flag }"
          v-for="item in effectTimeOptions"
          :key="item.value"
          @click="selectEffectTime(item)"
        >
          <div class="effect-time-name">{{ item.label }}</div>
        </div>
      </div>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import CheckinRuleTime from "./CheckinRuleTime";
import CheckinSpecialDate from "./CheckinSpecialDate";
import CheckinRuleLocation from "./CheckinRuleLocation";
import { ruleTypeList, weekDayOptions, effectTimeOptions, timeOptions, countOptions, dateOptions } from "../rule";
import { clone, omit } from "../../../../common/js/tool";

export default {
  name: "CheckinRuleForm",
  props: {
    rule: Object,
  },
  components: {
    CheckinRuleTime,
    CheckinSpecialDate,
    CheckinRuleLocation,
  },
  data() {
    return {
      form: {
        type: 1,
        method: 3,
        freeTimeSpace: 5,
        allow_complement: false,
        timeLimit: -1,
        count: -1,
        dateLimit: -1,
      },
      ruleTypeList,
      rules: {
        name: [{ required: true, message: "请输入规则名称", trigger: "blur" }],
        user_ids: [{ required: true, message: "请选择打卡人员", trigger: "change" }],
        type: [{ required: true, message: "请选择规则类型", trigger: "change" }],
        rule_time: [{ required: true, message: "请设置打卡时间", trigger: "change" }],
        freeRuleTime: [{ required: true, message: "请设置打卡时间", trigger: "change" }],
        freeTimeSpace: [{ required: true, message: "请设置打卡时间间隔", trigger: "change" }],
        method: [{ required: true, message: "请设置打卡方式", trigger: "change" }],
        location_config: [{ required: true, message: "请设置打卡位置", trigger: "change" }],
        allow_complement: [{ required: true, message: "请设置补卡申请", trigger: "change" }],
        effect_flag: [{ required: true, message: "请选择规则生效时间", trigger: "change" }],
      },
      radioStyle: {
        display: "block",
        height: "30px",
        lineHeight: "30px",
      },
      timeOptions,
      countOptions,
      dateOptions,
      spaceOptions: null,
      effectTimeOptions,
    };
  },
  computed: {
    dayOptions() {
      return weekDayOptions.map((item) => omit(item, "title"));
    },
  },
  created() {
    this.getComplementConf();
  },
  watch: {
    rule(val) {
      this.setForm(val);
    },
  },
  methods: {
    getComplementConf() {
      this.spaceOptions = new Array(288).fill().map((item, index) => {
        let value = index * 5;
        return { label: value + "分钟", value };
      });
    },
    setForm(val) {
      if (val) {
        this.form = clone(val);
      } else {
        this.form = {};
      }
    },
    selectRuleType(r) {
      this.$set(this.form, "type", r.key);
    },
    selectEffectTime(item) {
      this.$set(this.form, "effect_flag", item.value);
    },
    filterUserIds(user_ids) {
      let new_ids = [];
      let old_ids = this.rule.user_ids;
      user_ids.forEach((item) => {
        const target = old_ids.find((val) => val.type == item.type && val.id == item.id);
        if (!target) {
          new_ids.push(item);
        }
      });
      return new_ids;
    },
    // 检验打卡人员是否已有规则
    checkUser(user_ids) {
      let check_ids = null;
      if (this.rule) {
        // 编辑时，当前已选人员/部门/标签不参与检验
        check_ids = this.filterUserIds(user_ids);
      } else {
        check_ids = user_ids;
      }
      if (check_ids && check_ids.length > 0) {
        this.$axios({
          url: "/admin/checkin-rule/check",
          method: "POST",
          data: {
            user_ids: JSON.stringify(check_ids),
          },
        }).then((res) => {
          let deptRepeatList = res[0].intersect;
          let userRepeatList = res[1].intersect;
          let tagRepeatList = res[2].intersect;
          let repeat_ids = [];
          [deptRepeatList, userRepeatList, tagRepeatList].forEach((list) => {
            if (list && list.length > 0) {
              list.forEach((id) => {
                let index = user_ids.findIndex((user) => user.id == id);
                if (index > -1) {
                  let repeat = user_ids.splice(index, 1)[0];
                  repeat_ids.push(repeat);
                }
              });
            }
          });
          if (repeat_ids.length > 0) {
            this.$message.warning(
              <span>
                <span>以下成员已存在打卡规则，请勿重复添加</span>
                <select-book
                  multiple
                  type="book"
                  v-model={repeat_ids}
                  edit={false}
                  style="margin-top: 8px;"
                ></select-book>
              </span>
            );
            this.form.user_ids = user_ids;
          }
        });
      }
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            const item = effectTimeOptions.find(opt => opt.value == this.form.effect_flag);
            if(item) {
              this.$confirm({
                title: '提示',
                content: item.content,
                onOk: () => {
                  const data = this.getRuleFormData();
                  resolve(data);
                },
              });
            } else {
              this.$message.warning("请选择规则生效时间");
            }
          } else {
            reject();
          }
        });
      });
    },
    getRuleFormData() {
      let rule = this.rule;
      let form = this.form;
      let data = {
        name: form.name,
        user_ids: form.user_ids && JSON.stringify(form.user_ids),
        type: form.type,
        special_config: form.special_config,
        holiday_config: form.holiday_config ? 1 : 2,
        method: form.method,
        location_config: form.location_config && JSON.stringify(form.location_config),
        allow_complement: form.allow_complement ? 1 : 0,
        complement_config: JSON.stringify({
            timeLimit: form.timeLimit,
            count: form.count,
            dateLimit: form.dateLimit,
        }),
        effect_flag: form.effect_flag,
      };
      if (rule) {
        data.id = rule.id;
      }
      if (data.type == 1) {
        // 固定上下班
        const oldRuleTime = rule ? rule.rule_time : [];
        const newRuleTime = form.rule_time;
        const info = this.getRuleTimeUpdateInfo(newRuleTime, oldRuleTime);
        if(info) {
          data.rule_time = JSON.stringify(info);
        }
      } else if (data.type == 2) {
        // 自由上下班
        data.free_check_config = JSON.stringify({
          freeRuleTime: form.freeRuleTime,
          freeTimeSpace: form.freeTimeSpace,
        });
      }
      return data;
    },
    getRuleTimeUpdateInfo(newList, oldList) {
      const info = {};
      newList = newList || [];
      if(oldList && oldList.length > 0) {
        const children = clone(oldList);
        const add = [], update = [];
        newList.forEach(time => {
          if(time.id) {
            const index = children.findIndex(c => c.id == time.id);
            if(index >= 0) {
              update.push(time);
              children.splice(index, 1);
            } else {
              // 原打卡时间中找不到视为新增
              add.push(time);
            }
          } else {
            add.push(time);
          }
        });
        const del = children.map(c => c.id);
        add.length > 0 && (info.add = add);
        update.length > 0 && (info.update = update);
        del.length > 0 && (info.delete = del);
      } else if(newList.length > 0){
        info.add = newList;
      }
      return Object.keys(info).length > 0 ? info : null;
    },
  },
};
</script>

<style lang="less">
.rule-type-item {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding: 15px;
  width: 240px;
  border: 1px solid rgba(6, 15, 26, 0.1);
  border-radius: 4px;
  margin-right: 12px;
  cursor: pointer;
  &:hover {
    background-color: @background-color-base;
  }
  &.rule-type-select {
    background: rgba(38, 126, 240, 0.1);
    border: 1px solid #267ef0;
    .type-select-icon {
      display: block;
    }
  }
}
.type-select-icon {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top-right-radius: 3px;
  border-left: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-top: 12px solid #267ef0;
  border-right: 12px solid #267ef0;
}
.type-select-check {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(100%) translateY(-100%);
  font-size: 10px;
  color: #ffffff;
}
.rule-type-name {
  font-weight: 700;
  line-height: 20px;
}
.rule-type-desc {
  margin-top: 12px;
  height: 32px;
  font-size: 12px;
  line-height: 16px;
  color: rgba(11, 18, 26, 0.6);
}
.rule-form-tip {
  margin-left: 1em;
  color: @text-color-secondary;
}
.allow-complement-content {
  background-color: rgba(5, 14, 26, 0.03);
  padding: 16px;
  border: 1px solid rgba(5, 14, 26, 0.03);
  border-radius: 4px;
}
.effect-time-list {
  height: 40px;
}
.effect-time-item {
  border: 1px solid #d9d9d9;
  padding: 4px 8px;
  line-height: normal;
  border-radius: 4px;
  margin-right: 12px;
  cursor: pointer;
  &:hover {
    border: 1px solid #267ef0;
    color: #267ef0;
  }
}
.effect-time-select {
  border: 1px solid #267ef0;
  color: #267ef0;
}
</style>
