<template>
  <div class="period-time">
    <div class="period-time-box">
      <div class="period-text" :class="{'period-text-active': period == 1}" @click="selectPeriod($event, 1)">{{ getHourText(hour) }}</div>
      <div class="period-gap">:</div>
      <div class="period-text" :class="{'period-text-active': period == 2}" @click="selectPeriod($event, 2)">{{ getDoubleText(minute) }}</div>
    </div>
    <div class="period-time-options" :class="{'period-options-show': show}">
      <template v-if="period == 1">
        <div
          class="period-time-option"
          :class="{'period-time-select': hour == o}"
          v-for="o in hourOptions"
          @click="selectTime(o)"
          :key="o">{{ getHourText(o) }}</div>
      </template>
      <template v-else-if="period == 2">
        <div
          class="period-time-option"
          :class="{'period-time-select': minute == o}"
          @click="selectTime(o)"
          v-for="o in minuteOptions"
          :key="o">{{ getDoubleText(o) }}</div>
      </template>
    </div>
  </div>
</template>

<script>
    import {getDoubleText, isEmpty} from "../../../../common/js/tool";
    import { getTimeArray, getHourText } from "../rule";

    export default {
        name: "period-time",
        model: {
            event: "change"
        },
        props: {
            value: String,
            startTime: String,
            endTime: String,
        },
        data() {
            return {
                period: 0,
                show: false,
                target: null
            }
        },
        computed: {
            start() {
                return getTimeArray(this.startTime);
            },
            end() {
                return getTimeArray(this.endTime);
            },
            time() {
                return getTimeArray(this.value);
            },
            hourOptions() {
                const res = [];
                const sh = this.start[0] || 0;
                const eh = this.end[0] || 47;
                for(let i = sh; i <= eh; i ++) {
                    res.push(i);
                }
                return res;
            },
            minuteOptions() {
                let s = 0, e = 59;
                const sh = this.start[0] || 0;
                const eh = this.end[0] || 47;
                const hour = this.hour;
                if(hour <= sh) {
                    s = isEmpty(this.start[1]) ? 0 : this.start[1];
                } else if(hour >= eh) {
                    e = isEmpty(this.end[1]) ? 59 : this.end[1];
                }
                const res = [];
                for(let i = s; i <= e; i ++) {
                    res.push(i)
                }
                return res;
            },
            hour: {
                get() {
                    return this.time[0];
                },
                set(val) {
                    this.$emit("change", `${getDoubleText(val)}:${getDoubleText(this.minute)}`);
                }
            },
            minute: {
                get() {
                    return this.time[1];
                },
                set(val) {
                    this.$emit("change", `${getDoubleText(this.hour)}:${getDoubleText(val)}`);
                }
            },
        },
        created() {
            document.addEventListener("click", this.handleClick);
        },
        beforeDestroy() {
            document.removeEventListener("click", this.handleClick);
        },
        methods: {
            getDoubleText,
            getHourText,
            selectTime(t) {
                if(this.period == 1) {
                    this.hour = t;
                } else if(this.period == 2) {
                    this.minute = t;
                }
                this.hideOptions();
            },
            selectPeriod(e, period) {
                this.target = e.currentTarget;
                this.period = period;
                this.show = true;
            },
            hideOptions() {
                this.show = false;
                this.period = 0;
                this.target = null;
            },
            handleClick(e) {
                if(e.target !== this.target) {
                    this.hideOptions();
                }
            },
        }
    }
</script>

<style lang="less">
.period-time {
  position: relative;
}
.period-time-box {
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 28px;
  border: var(--border);
  border-radius: 4px;
  line-height: 20px;
}
.period-gap {
  margin: 0 2px;
}
.period-text {
  padding: 0 4px;
  cursor: pointer;
  &.period-text-active {
    background-color: @primary-1;
  }
}
.period-time-options {
  display: none;
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  padding: 0 4px;
  max-height: 200px;
  border-radius: 4px;
  background-color: @component-background;
  box-shadow: @box-shadow-base;
  z-index: 1;
  overflow: auto;
  text-align: center;
  &.period-options-show {
    display: block;
  }
}
.period-time-option {
  height: 24px;
  border-radius: 4px;
  line-height: 24px;
  cursor: pointer;
  &.period-time-select {
    background-color: @primary-1;
  }
  &:hover {
    background-color: @primary-2;
  }
}
</style>
